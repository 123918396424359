@import url('../node_modules/@fortawesome/fontawesome-free/css/all.min.css');


.toggle_dot {
  top: -.25rem;
  transition: all 0.3s ease-in-out;
  }
  input:checked~.toggle_dot {
  transform: translateX(100%);
  background-color: rgba(165, 180, 252, var(--tw-text-opacity));
}
  